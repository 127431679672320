<template>
  <div class="receives-list-page fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="paymentsList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="handleRowClicked($event)"
                             @load:more="getReceives()"/>

    <button id="printTableReceive" v-show="false" @click="handlePrintReceive"></button>
  </div>
</template>

<script>
import axios from "axios";
import {addComma, getTimeFromServer, hideLoading, showLoading} from "../../../../../assets/js/functions";
import {getReceives, printReceive} from "../../../../../http/requests/treasury/receive";
import {getBanks} from "../../../../../http/requests/treasury/banks";
import {getCashBoxes} from "../../../../../http/requests/treasury/cashBoxes";
import {getPaymentGateways} from "../../../../../http/requests/treasury/paymentGateways";
import historyStates from "@/mixins/historyStates";
import setDocumentDescription from "@/mixins/setDocumentDescription";

export default {
  name: 'receivesList',
  metaInfo () {
    return {
      title: this.$t('treasury.receive.list.title')
    }
  },
  mixins: [historyStates, setDocumentDescription],
  data () {
    return {
      loadingTimer: 0,
      options: {
        id: 'receivesList',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'status',
          i18n: 'treasury.receive.table.header.status',
          width: 'calc(100% / 9 * 2)',
          minWidth: 150,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('treasury.receive.statusTypes.all'),
              value: -1
            },
            {
              label: this.$t('treasury.receive.statusTypes.confirm'),
              value: 1
            },
            {
              label: this.$t('treasury.receive.statusTypes.cancel'),
              value: 0
            }
          ]
        },
        {
          field: 'type',
          i18n: 'treasury.receive.table.header.type',
          width: 'calc(100% / 9)',
          minWidth: 150,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('treasury.receive.labels.all'),
              value: ''
            },
            {
              label: this.$t('treasury.receive.actionTypes.online'),
              value: 'online'
            },
            {
              label: this.$t('treasury.receive.actionTypes.in-person'),
              value: 'in-person'
            }
          ]
        },
        {
          field: 'price',
          i18n: 'treasury.receive.table.header.price',
          width: 'calc(100% / 9)',
          minWidth: 150,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {}
        },
        {
          field: 'paymentConfirm',
          i18n: 'treasury.receive.table.header.paymentConfirm',
          width: 'calc(100% / 9)',
          minWidth: 120,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {}
        },
        {
          field: 'transactionId',
          i18n: 'treasury.receive.table.header.transactionId',
          width: 'calc(100% / 9)',
          minWidth: 120,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {}
        },
        /*{
          field: 'description',
          i18n: 'treasury.receive.table.header.description',
          width: 'calc(100% / 9 * 2)',
          minWidth: 200,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },*/
        {
          field: 'payee',
          i18n: 'treasury.receive.table.header.destination',
          width: 'calc(100% / 9 * 2)',
          minWidth: 150,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('treasury.receive.labels.all'),
              value: ''
            }
          ]
        },
        {
          field: 'payer',
          i18n: 'treasury.receive.table.header.source',
          width: 'calc(100% / 9 * 2)',
          minWidth: 150,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'date',
          i18n: 'treasury.receive.table.header.date',
          width: 'calc(100% / 9)',
          minWidth: 130,
          locked: true,
          sortable: true,
          align: 'center',
          filter: true,
          filterType: 'date',
          filterRange: true
        },
        {
          field: 'id',
          i18n: 'treasury.receive.table.header.docNumber',
          width: 'calc(100% / 9)',
          minWidth: 80,
          locked: true,
          align: 'center',
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {
            type: 'auto-counter'
          }
        }
      ],
      sourceTypesMapper: {
        'User': this.$t('treasury.receive.types.user'),
        'Bank': this.$t('treasury.receive.types.bank'),
        'Cash': this.$t('treasury.receive.types.cash'),
        'Storeroom': this.$t('treasury.receive.types.storeroom'),
        'PaymentGateway': this.$t('treasury.receive.types.paymentGateway')
      },
      data: [],
      filters: {},
      selectedFilters: [],
      sorts: ['order[0]=date,desc'],
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: {name: 'insertTreasuryReceive'},
              type: 'link',
              icon: 'PLUS',
              iconPack: 'useral',
              color: 'success',
              permission: 'receive.create'
            }
          ],
          leftToolbar: [
            // {
            //   id: 'printTableReceive',
            //   // i18n: 'draggableDynamicTable.actions.print',
            //   icon: 'PRINT',
            //   iconPack: 'useral'
            // },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'DOWNLOAD',
              iconPack: 'useral'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    })

    this.getBanksFilters()
    this.getCashBoxFilters()
    this.getPaymentGatewaysFilters()
    if (history.state.hasOwnProperty('data') && this.$store.state.helper.prevRoute.name !== null) {
      this.getTableInfoFromHistory()
    } else {
      this.getReceives()
    }
  },
  methods: {
    getReceives () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.paymentsList && this.data.length === 0) this.$refs.paymentsList.loadMoreStatus = 'FirstLoad'
          else if (this.$refs.paymentsList && this.data.length > 0) this.$refs.paymentsList.loadMoreStatus = 'Loading'

          getReceives(this.page, this.filters, this.sorts).then(response => {
            this.endedList = response.data.data.length === 0
            const documents = response.data.data
            // let banks = this.banks
            documents.forEach((document) => {
              this.data.push({
                route: {
                  name: 'treasuryReceiveDocument',
                  params: {id: document.id}
                },
                id: document.document_id,
                description: document.description,
                payer: `${document.payer.name || ''} ${document.payer.family || ''}`,
                payee: document.payee.type === 'booking_app' ? this.getPayerName(document.payee.type, document.payee) : document.payee.name || document.payee.title,
                transactionId: document.transaction_id || '',
                paymentConfirm: document.confirmation_id || '',
                price: {
                  type: 'price',
                  value: document.price
                },
                status: {
                  value: document.status === 1 ? this.$t('treasury.receive.statusTypes.confirm') : this.$t('treasury.receive.statusTypes.cancel'),
                  styles: document.status === 0 ? 'color: #ea5455;' : ''
                },
                type: {
                  value: this.$t(`treasury.receive.actionTypes.${document.type}`)
                },
                date: document.date
              })
            })

            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('id')
              this.columnsLabel[row_index].footer.value = response.data.pagination.total
            }
            const price_index = this.columnsLabel.map((e) => {
              return e.field
            }).indexOf('price')
            this.columnsLabel[price_index].footer = {
              value: addComma(response.data.extra.total_price)
            }

            if (this.$refs.paymentsList) this.$refs.paymentsList.loadMoreStatus = ''
          }).catch((error) => {
            console.log(error)
            if (this.$refs.paymentsList && !axios.isCancel(error)) this.$refs.paymentsList.loadMoreStatus = 'Danger'
          })
        }
      }, 400)
    },
    setFilters (filters) {
      this.selectedFilters = filters
      const filters_list = {}
      Object.keys(filters).forEach((key) => {
        switch (key) {

          case 'id':
            if (filters[key].search !== '') filters_list.document = `${  filters[key].search},${  filters[key].type.id}`
            break

          case 'creator':
            if (filters[key].search !== '') filters_list.creator = filters[key].search
            break

          case 'description':
            if (filters[key].search !== '') filters_list.description = filters[key].search
            break

          case 'price':
            if (filters[key].search !== '') filters_list.price = `${  filters[key].search},${  filters[key].type.id}`
            break

          case 'transactionId':
            if (filters[key].search !== '') filters_list.transaction = `${  filters[key].search},${  filters[key].type.id}`
            break

          case 'paymentConfirm':
            if (filters[key].search !== '') filters_list.confirmation = `${  filters[key].search},${  filters[key].type.id}`
            break

          case 'payee':
            if (filters[key].search.value.length > 0) filters_list.payee = filters[key].search.value
            break

          case 'payer':
            if (filters[key].search !== '') filters_list.payer = filters[key].search
            break

          case 'date':
            if (filters[key].search.length > 0) filters_list.date = filters[key].search.join('_')
            break

          case 'type':
            if (filters[key].search.value !== '') filters_list.type = filters[key].search.value
            break

          case 'status':
            if (filters[key].search.value > -1) filters_list.status = filters[key].search.value
            break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.$nextTick(() => {
        this.getReceives()
      })
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

          case 'id':
            sorts_list.push(`order[0]=document_id,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'creator':
            sorts_list.push(`order[0]=creator,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'description':
            sorts_list.push(`order[0]=description,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'price':
            sorts_list.push(`order[0]=price,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'transactionId':
            sorts_list.push(`order[0]=transaction_id,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'paymentConfirm':
            sorts_list.push(`order[0]=confirmation_id,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'payer':
            sorts_list.push(`order[0]=payer,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'payee':
            sorts_list.push(`order[0]=payee_id,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'date':
            sorts_list.push(`order[0]=date,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'type':
            sorts_list.push(`order[0]=type,${  columns[key] ? 'desc' : 'asc'}`)
            break

          case 'status':
            sorts_list.push(`order[0]=status,${  columns[key] ? 'desc' : 'asc'}`)
            break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.$nextTick(() => {
        this.getReceives()
      })
    },
    handlePrintReceive () {
      showLoading()

      printReceive(this.filters, this.sorts).then(response => {
        hideLoading()

        const data = response.data

        let time = getTimeFromServer()
        let fileURL = window.URL.createObjectURL(new Blob([data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `receiveList-${ time }.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
        .catch((error) => {
          hideLoading()

          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('treasury.receive.notifications.print.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
    },
    getBanksFilters () {
      getBanks().then(response => {
        const banks = response.data.data
        const payeeIndex = this.columnsLabel.map(e => e.field).indexOf('payee')
        banks.forEach((bank) => {
          if (payeeIndex > -1) {
            this.columnsLabel[payeeIndex].filterTypes.push({
              label: bank.name,
              value: bank.name
            })
          }
        })
      })
    },
    getCashBoxFilters () {
      getCashBoxes().then(response => {
        const cashBoxes = response.data.data
        const payeeIndex = this.columnsLabel.map(e => e.field).indexOf('payee')
        cashBoxes.forEach((cashBox) => {
          if (payeeIndex > -1) {
            this.columnsLabel[payeeIndex].filterTypes.push({
              label: cashBox.name,
              value: cashBox.name
            })
          }
        })
      })
    },
    getPaymentGatewaysFilters () {
      getPaymentGateways().then(response => {
        const paymentGateways = response.data.data
        const payeeIndex = this.columnsLabel.map(e => e.field).indexOf('payee')
        paymentGateways.forEach((paymentGateway) => {
          if (payeeIndex > -1) {
            this.columnsLabel[payeeIndex].filterTypes.push({
              label: paymentGateway.title,
              value: paymentGateway.title
            })
          }
        })
      })
    },
    handleRowClicked () {
      this.setTableInfoToHistory()
    }
  }
}
</script>

<style lang="scss">
  @media print {
    .receives-list-page {
      height: max-content;
      /*padding-top: 100px;*/
    }
  }

  @page {
    .receives-list-page {
      margin-top: 30px;

    }
    padding-top: 3cm !important;
  }

</style>
